import { useState, useRef, ReactElement, ReactNode } from 'react'
import { Trans } from '@lingui/macro'
import { Button } from '@discogs/amped/button'
import FocusTrap from 'focus-trap-react'
import { esc } from '../../keycodes'
import { Close } from '../icon'
import css from './styles.css'
import { useKeyPress } from '../../use-key-press'

export type Confirm = {
    isOpen: boolean
    ask: () => Promise<boolean>
    accept: () => void
    cancel: () => void
    props: {
        open: boolean
        onConfirm: () => void
        onCancel: () => void
    }
}

export function useConfirm(open: boolean = false): Confirm {
    const [isOpen, setIsOpen] = useState(open)
    const ref = useRef<((ok: boolean) => void) | null>(null)

    function accept(): void {
        if (ref.current) {
            ref.current(true)
            setIsOpen(false)
        }
    }

    function cancel(): void {
        if (ref.current) {
            ref.current(false)
            setIsOpen(false)
        }
    }

    return {
        isOpen,
        ask(): Promise<boolean> {
            setIsOpen(true)
            return new Promise(function (resolve: (ok: boolean) => void): void {
                ref.current = resolve
            })
        },
        accept,
        cancel,
        props: {
            open: isOpen,
            onConfirm: accept,
            onCancel: cancel,
        },
    }
}

type ModalState = {
    isOpen: boolean
    open: () => void
    cancel: () => void
    props: {
        open: boolean
        onOpen: () => void
        onCancel: () => void
    }
}

export function useModal(initialOpen: boolean = false): ModalState {
    const [isOpen, setIsOpen] = useState(initialOpen)
    const open = (): void => setIsOpen(true)
    const cancel = (): void => setIsOpen(false)

    return {
        isOpen,
        open,
        cancel,
        props: {
            open: isOpen,
            onOpen: open,
            onCancel: cancel,
        },
    }
}

type Props = {
    open?: boolean
    onClose?: () => void
    title?: ReactElement | string
    children: ReactNode
}

export function Modal(props: Props): ReactElement | null {
    const { open, children, title, onClose } = props
    if (!open) {
        return null
    }

    const close = onClose && (
        <button className={css.close} title='Close' onClick={onClose}>
            <Close />
        </button>
    )

    return (
        <div className={css.overlay} aria-modal='true'>
            <FocusTrap>
                <div className={css.modal}>
                    {(title || close) && (
                        <h3 className={css.title}>
                            {title}
                            {close}
                        </h3>
                    )}
                    {children}
                </div>
            </FocusTrap>
        </div>
    )
}

type ConfirmationProps = Props & {
    cancelText?: ReactElement
    confirmText?: ReactElement
    onCancel: () => void
    onConfirm: () => void
    danger?: boolean
    disabled?: boolean
}

export function Confirmation(props: ConfirmationProps): ReactElement | null {
    const {
        cancelText = <Trans>Cancel</Trans>,
        confirmText = <Trans>OK</Trans>,
        onConfirm,
        onCancel,
        children,
        danger,
        disabled = false,
        ...rest
    } = props

    useKeyPress({ [esc]: onCancel })

    return (
        <Modal {...rest}>
            {children}
            <div className={css.buttons}>
                <Button
                    variant={danger ? 'warning' : 'mpPrimary'}
                    onClick={onConfirm}
                    disabled={disabled}
                    title={disabled ? 'Please select a folder from the dropdown' : ''}
                >
                    {confirmText}
                </Button>
                <Button onClick={onCancel}>{cancelText}</Button>
            </div>
        </Modal>
    )
}
