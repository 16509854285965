import { createContext, useContext } from 'react'
import { ApolloClient } from '@apollo/client'

import { PageCategory } from './category'
import { ObjectType } from './object-type'

export type PageContext = {
    pageCategory: PageCategory
    objectType: ObjectType
    discogsId?: number
    gtm?: Record<string, unknown>
    interaction?: (client: ApolloClient<unknown>, discogsId?: number) => Record<string, unknown> | null
}

export const context = createContext<PageContext | null>(null)

export function usePageContext(): PageContext {
    const ctx = useContext(context)
    if (ctx) {
        return ctx
    }
    throw new Error('No PageContext found, make sure the page is wrapped with page()')
}
