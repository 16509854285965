import { ReactElement, ReactNode } from 'react'

import { config } from '../../../config'
import { useLogin } from '../../login'
import { useCookie } from '../../cookies'
import { useDataLayer } from '../../data-layer'
import { useLocalisation } from '../../i18n'

import { PageCategory } from './category'
import { ObjectType } from './object-type'
import { context, PageContext } from './context'

export type PageProps = {
    children: ReactNode
    discogsId?: number
    adTargeting?: () => Promise<Record<string, unknown>>
}

// The DatalayerInitialisedEvent needs to be called on each page,
// preferably in the document head.
type DataLayerInitializedEvent = {
    event: 'dataLayer-initialized'
    environment?: string
    isNewReleasePage: true
    isCatalogPage: true

    // The view name
    pageCategory: PageCategory

    // CD 1 - The user id
    userId?: number

    // CD 5 - Wether or not the user is logged in
    loggedIn: boolean

    // CD 41 -  the current version of the site
    siteVersion: string
}

type GtmJsEvent = {
    event: 'gtm.js'
    'gtm.start': number
    'user.loggedIn': boolean
    'user.locale': string
    'user.isRegistered': boolean
    objectType: ObjectType

    // TODO: implement these fields
    // 'user.isStaff': boolean
    // 'user.inLabs': boolean
    // 'user.submissionCount': number
    // 'user.orderCount': number
    // 'user.purchaseCount': number
    // 'user.appCount': number
    // 'user.usesOfficialApp': boolean
    // 'user.collectionCount': number
    // 'user.wantlistCount': number
}

export function PageProvider(props: PageContext & PageProps): ReactElement {
    const { children, ...ctx } = props
    const { user, loggedIn } = useLogin()
    const { language } = useLocalisation()
    const [ckUsername] = useCookie('ck_username')

    const initialized: DataLayerInitializedEvent = {
        event: 'dataLayer-initialized',
        pageCategory: ctx.pageCategory,
        userId: user?.discogsId,
        loggedIn,
        siteVersion: config.BUILD_ID,
        environment: config.NODE_ENV ?? undefined,
        isNewReleasePage: true,
        isCatalogPage: true,
    }

    const gtmjs: GtmJsEvent = {
        event: 'gtm.js',
        objectType: ctx.objectType,
        'gtm.start': Date.now(),
        'user.loggedIn': loggedIn,
        'user.locale': language,
        'user.isRegistered': Boolean(ckUsername),
        ...ctx.gtm,
    }

    useDataLayer({ event: initialized, ssr: true })
    useDataLayer({ event: gtmjs, ssr: true })

    return <context.Provider value={ctx}>{children}</context.Provider>
}
